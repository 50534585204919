.textarea-form-item {
  display: flex;

  &-textarea {
    width: 100%;
    font-size: 14px;
    appearance: none;
    padding: 11px 12px;
    line-height: 19px;
    outline: 0;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    box-sizing: border-box;
    color: var(--primary-text-color);
    font-weight: 400;
    border-radius: var(--border-radius-50);
    max-height: 200px;

    &:focus {
      outline: none !important;
      border: 1px solid var(--primary-color-AA) !important;
      box-shadow: 0 0 0px 3px var(--primary-color-20);
    }

    &::placeholder {
      color: var(--primary-text-color);
      opacity: 0.6;
    }

    &--invalid,
    &--invalid:focus {
      border: 1px solid #ed4337;
    }

    @media screen and (max-device-width: 600px) {
      font-size: 16px;
      line-height: 21px;
    }
  }
}

.main-app--survey,
.main-app--survey-full {
  .textarea-form-item {
    &-textarea {
      font-size: 15px !important;
      background-color: var(--background-color) !important;
      font-weight: 400 !important;
      border-radius: var(--border-radius-50) !important;
      padding: 14px 12px !important;

      @media screen and (max-device-width: 600px) {
        font-size: 16px !important;
      }
    }
  }
}
