.news-content-body {
  code {
    caret-color: white;
    color: white;
    background-color: #171717;
    border-radius: 0.125rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-family: monospace;
  }

  code::selection {
    background-color: rgba(255, 255, 255, 0.3);
  }

  pre {
    caret-color: white;
    background-color: #404040;
    color: white;
    border-radius: 0.25rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 1rem;
    border: 1px solid black;
    background-color: #171717;
    border-color: #1f2937;
  }

  pre *::selection {
    background-color: rgba(255, 255, 255, 0.2);
  }

  pre code {
    background-color: inherit;
    color: inherit;
    padding: 0;
    box-shadow: none;
  }

  pre .hljs-comment,
  pre .hljs-quote {
    color: #a3a3a3;
  }

  pre .hljs-variable,
  pre .hljs-template-variable,
  pre .hljs-attribute,
  pre .hljs-tag,
  pre .hljs-name,
  pre .hljs-regexp,
  pre .hljs-link,
  pre .hljs-selector-id,
  pre .hljs-selector-class {
    color: #f87171;
  }

  pre .hljs-number,
  pre .hljs-meta,
  pre .hljs-built_in,
  pre .hljs-builtin-name,
  pre .hljs-literal,
  pre .hljs-type,
  pre .hljs-params {
    color: #fb923c;
  }

  pre .hljs-string,
  pre .hljs-symbol,
  pre .hljs-bullet {
    color: #a3e635;
  }

  pre .hljs-title,
  pre .hljs-section {
    color: #facc15;
  }

  pre .hljs-keyword,
  pre .hljs-selector-tag {
    color: #2dd4bf;
  }

  pre .hljs-emphasis {
    font-style: italic;
  }

  pre .hljs-strong {
    font-weight: 700;
  }
}
