.multiple-choice-form-item {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  padding-left: 2px;

  &-choice {
    min-height: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    border: 1px solid var(--background-color-dark);
    color: var(--secondary-text-color);
    border-radius: var(--border-radius-25);
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    &-shortcut {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: var(--border-radius-15);
      margin-right: 10px;
      margin-left: 8px;
      background-color: var(--background-color);
      color: var(--primary-color);
      border: 1px solid var(--primary-color-55);
      flex-shrink: 0;
    }

    &-check {
      display: none;
      flex-shrink: 0;
      margin-right: 10px;
      justify-content: center;
      align-items: center;

      svg {
        width: 16px;
        height: auto;
        fill: var(--primary-color);
      }
    }

    &-value {
      font-size: 16px;
      text-align: left;
      font-weight: 400;
      color: var(--primary-text-color);
      flex-grow: 1;
    }

    &--selected,
    &:hover {
      background-color: var(--primary-color-11);
      border: 1px solid var(--primary-color);

      .multiple-choice-form-item-choice-shortcut {
        background-color: var(--primary-color);
        color: var(--primary-contrast-color);
        border: 1px solid var(--primary-color);
      }

      .multiple-choice-form-item-choice-value {
        color: var(--primary-color);
      }
    }

    &--selected {
      .multiple-choice-form-item-choice-check {
        display: flex;
      }
    }
  }
}

.multiple-choice-form-item-choice-priority {
  width: 12px;
  height: 12px;
  border-radius: 99px;
  background-color: var(--primary-color);
  margin: 0 10px;

  &--0 {
    background-color: rgb(90, 176, 124) !important;
  }
  &--1 {
    background-color: rgb(227, 177, 57) !important;
  }
  &--2 {
    background-color: rgb(229, 13, 0) !important;
  }
}