.form-loading {
  padding: 70px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-sent {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke-miterlimit: 10;
    animation: bb-suc-fill 0.4s ease-in-out 0.4s forwards,
      bb-suc-scale 0.3s ease-in-out 0.9s both;

    path {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      stroke: var(--background-color);
      animation: bb-suc-stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 3;
      stroke-miterlimit: 10;
      fill: none;
      animation: bb-suc-stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
  }

  &-label {
    color: var(--primary-text-color);
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    line-height: 21px;
  }
}

.main-app--survey,
.main-app--survey-full {
  .form-sent-label {
    font-size: 16px;
  }
}

.form-error {
  background-color: #da0e0711;
  padding: 16px;
  margin: 25px;
  margin-bottom: -10px;
  border-radius: var(--border-radius-60);
  font-size: 14px;
  font-weight: normal;
  color: #da0e07;
}

.form-container-main {
  max-width: 550px;
  margin: auto;
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  max-height: min(450px, calc(100vh - 65px));
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  .form-container-animation--forwards {
    .form-item-animation-enter-active {
      animation: fadeInFormForwards 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .form-item-animation-exit-active {
      animation: fadeOutFormForwards 250ms both cubic-bezier(0.165, 0.84, 0.44, 1);
      position: absolute;
      top: 25px;
      width: calc(100% - 50px);
    }
  }

  .form-container-animation--backwards {
    .form-item-animation-enter-active {
      animation: fadeInFormBackwards 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .form-item-animation-exit-active {
      animation: fadeOutFormBackwards 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
      position: absolute;
      top: 25px;
      width: calc(100% - 50px);
    }
  }
}

.main-app--survey-full {
  .form-container-main {
    max-height: initial !important;
    padding: 30px;
  }
}

.main-app--survey {
  .form-container-main {
    max-height: initial !important;
    padding: 30px;
  }
}

@keyframes fadeInFormForwards {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
    transform: translate3d(33%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutFormForwards {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-33%, 0, 0);
  }
}

@keyframes fadeInFormBackwards {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
    transform: translate3d(-33%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutFormBackwards {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(33%, 0, 0);
  }
}

@keyframes bb-suc-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes bb-suc-scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes bb-suc-fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--primary-color);
  }
}