.news-content-body {
  /* Image Block */
  figure[data-type='imageBlock'] {
    margin: 0;
  }

  figure[data-type='imageBlock'] img {
    border-radius: 0.25rem;
    display: block;
    width: 100%;
  }

  /* Block Quote */
  figure[data-type='blockquoteFigure'] {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 0px;
    color: #000;
  }

  figure[data-type='blockquoteFigure'],
  .news-content-body > blockquote {
    blockquote {
      margin: 0;
    }

    blockquote > *:first-child {
      margin-top: 0;
    }

    blockquote > *:last-child {
      margin-bottom: 0;
    }
  }

  /* Columns */
  [data-type='columns'] {
    display: grid;
    gap: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  [data-type='columns'].layout-sidebar-left {
    grid-template-columns: 40fr 60fr;
  }

  [data-type='columns'].layout-sidebar-right {
    grid-template-columns: 60fr 40fr;
  }

  [data-type='columns'].layout-two-column {
    grid-template-columns: 1fr 1fr;
  }

  [data-type='column'] {
    overflow: auto;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      background-color: #f3f4f6;
    }

    [data-type="detailsContent"] {
      padding-left: 20px;
    }
  }

  .details summary {
    font-weight: bold;
    display: block;
  }

  .details > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    height: 1.25rem;
    width: 1.25rem;
  }

  .details > button:hover {
    background-color: #d1d5db;
  }

  .details > button:hover.dark {
    background-color: #1f2937;
  }

  .details > button::before {
    content: '\25B6';
  }

  .details.is-open > button::before {
    transform: rotate(90deg);
  }

  .details > summary::before {
    content: '▶';
    display: inline-block;
    margin-right: 0.5rem;
    transition: transform 0.3s ease;
    font-size: 14px;
  }
  
  .details[open] > summary::before {
    transform: rotate(90deg);
  }

  .details

  .details > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .details > div > .detailsContent > :last-child {
    margin-bottom: 0.5rem;
  }



  .details .details {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
}
