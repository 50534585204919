.home-page--classic {
  .home-page-header {
    padding: 40px 40px 0px;
  }

  .home-page-inner {
    align-items: center;
    justify-content: flex-start;
    margin: auto;
    padding-top: 72px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    overflow: auto;

    .operator-info-team-image {
      margin-left: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .classic-menu {
      width: 100%;
      max-width: 460px;
    }

    .home-page-intro {
      padding: 25px;
      padding-top: 70px;
    }

    .powered-by {
      padding: 0px;
      margin-top: 20px;
      margin-left: 0px;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}