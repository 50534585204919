.bot-action-calendly-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;

  &-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 12px;
    color: var(--primary-text-color);
  }

  button {
    background-color: var(--primary-color);
    color: var(--primary-contrast-color);
    border-radius: var(--border-radius-60);
    border: none;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    padding: 12px 17px;
    text-align: center;
    width: auto;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    flex-shrink: 0;
    outline: none;

    svg {
      width: 10px;
      height: auto;
      fill: var(--primary-color);
    }

    &:hover {
      background-color: var(--primary-color);
      color: var(--primary-contrast-color);


      svg {
        fill: var(--primary-contrast-color);
      }
    }
  }
}