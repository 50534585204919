.news-content-body {
  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  ul,
  ol {
    padding: 0 2rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  ul,
  ol,
  ul ul,
  ol ol,
  ul li,
  ol li {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  ul p,
  ol p {
    margin-top: 0;
    margin-bottom: 0.25rem;
  }

  ul[data-type='taskList'] {
    list-style-type: none;
    padding: 0;
  }

  ul[data-type='taskList'] p {
    margin: 0;
  }

  ul[data-type='taskList'] li {
    display: flex;
  }

  ul[data-type='taskList'] li > label {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    user-select: none;
  }

  ul[data-type='taskList'] li > div {
    flex-basis: auto;
    flex-grow: 1;
  }

  ul[data-type='taskList'] li[data-checked='true'] {
    text-decoration: line-through;
  }
}
