.widget-menu-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  border-radius: var(--border-radius-50);
  background-color: var(--background-color);
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: 0px;
  }

  &-icon {
    padding: 0px;
    box-sizing: border-box;
    flex-shrink: 0;
    margin-left: 15px;


    img, svg {
      height: 16px;
      width: 16px;
      max-height: 16px;
      max-width: 16px;
      flex-shrink: 0;
      object-fit: contain;
    }
  }

  &-labels {
    flex-grow: 1;
    display: inline-block;
    max-width: calc(100% - 40px);
  }

  &-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 3px;
    color: var(--primary-text-color);
  }

  @media (hover: hover) {
    &:hover {
      .widget-menu-button-title {
        color: var(--primary-color);
      }
    }
  }

  &-description {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--secondary-text-color);
    display: block;
  }
}
