.chat-message-composer-bg {
  content: " ";
  position: absolute;
  bottom: 0px;
  left: 24px;
  right: 24px;
  height: 50px;
  z-index: 999;
  animation: menuFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background-color: var(--background-color);
}

.chat-message-composer {
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  border-radius: 25px;
  background-color: var(--background-color);
  box-shadow: rgba(0, 0, 0, 0.03) 2px 0px 10px 3px;
  border: 1px solid var(--border-color-33);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  animation: menuFadeIn;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  z-index: 1000;

  &:focus-within {
    box-shadow: rgba(0, 0, 0, 0.03) 2px 0px 10px 3px,
      0 0 0 2px var(--primary-color);
    animation: blink_input 0.01s;
  }

  &-chat {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &-attachment {
      padding-left: 15px;
      padding-bottom: 9px;
      margin-top: -5px;

      &-file {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid var(--border-color-33);
        padding: 8px;
        border-radius: 34px;

        &-icon {
          width: 15px;
          height: 15px;
          fill: var(--primary-text-color);
        }

        &-name {
          font-size: 13px;
          color: var(--primary-text-color-54);
          font-weight: 400;
          margin-left: 8px;
          margin-right: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
        }

        &-remove {
          display: block;
          width: 18px;
          height: 18px;
          cursor: pointer;
          fill: var(--primary-text-color);

          &:hover {
            fill: red;
          }
        }
      }

      &-image {
        position: relative;
        width: 42px;
        height: 42px;

        &-remove {
          display: none;
          position: absolute;
          top: -6px;
          right: -6px;
          width: 18px;
          height: 18px;
          cursor: pointer;
          background-color: #000;
          border-radius: 50%;
          justify-content: center;
          align-items: center;

          svg {
            width: 14px;
            height: 14px;
          }

          svg,
          path {
            fill: #fff;
          }

          &:hover {
            background-color: red;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-width: 40px;
          max-height: 40px;
          border-radius: 10px;
        }

        &:hover {
          .chat-message-composer-chat-attachment-image-remove {
            display: flex;
          }
        }
      }
    }
  }

  textarea {
    flex-grow: 1;
    border: none;
    resize: none;
    outline: 0;
    appearance: none;
    padding: 15px 20px;
    padding-right: 0px;
    line-height: 19px;
    font-size: 14px;
    box-sizing: border-box;
    color: var(--primary-text-color);
    font-weight: 400;
    max-height: 400px;
    background-color: transparent;
    width: 100%;

    @media screen and (max-device-width: 600px) {
      font-size: 16px;
    }

    &::placeholder {
      color: var(--primary-text-color);
      opacity: 0.6;
    }
  }

  .composer-buttons-audio-recording-active {
    width: 100%;
  }

  .composer-buttons {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-height: 50px;

    .attachment-loading {
      .gleap-loading {
        width: 20px;
        height: 20px;
        margin-left: 18px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-top: 4px;

        > div {
          width: 20px;
          height: 20px;
        }
      }
    }

    .attachment-button {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 10px;
      margin-right: 0px;
      margin-bottom: 0px;
      fill: var(--primary-text-color);

      @media (hover: hover) {
        &:hover {
          opacity: 0.6;
        }
      }

      &--fileselected {
        fill: var(--primary-color);

        path,
        rect {
          fill: var(--primary-color);
        }
      }
    }
  }
}

.send-button {
  cursor: pointer;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-left: 12px;
  margin-right: 9px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    fill: var(--primary-contrast-color);
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }

  &--disabled {
    background-color: var(--background-color-dark);

    svg {
      fill: var(--primary-text-color-54);
    }
  }
}
