.home-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  margin-bottom: 7px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--background-color-99);
  backdrop-filter: blur(10px);
  border: 1px solid var(--background-color-EE);
  height: 42px;
  border-radius: 42px;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  &-icon {
    padding: 0px;
    box-sizing: border-box;
    flex-shrink: 0;

    img, svg {
      height: 100%;
      width: 16px;
      max-height: 16px;
      object-fit: contain;
    }
  }

  &-indicator {
    width: 7px;
    margin-left: 10px;
    opacity: 0.8;

    path {
      fill: var(--primary-color);
    }
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-left: 10px;
    color: var(--primary-text-color);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--background-color);
      border: 1px solid var(--background-color);

      svg, svg path {
        path: var(--primary-color);
        fill: var(--primary-color);
      }
  
      .home-button-title {
        color: var(--primary-color);
      } 
    }
  }

  &:last-of-type {
    margin-bottom: 0px; 
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);

    svg, svg path {
      path: var(--primary-contrast-color);
      fill: var(--primary-contrast-color);
    }

    .home-button-title {
      color: var(--primary-contrast-color);
    }

    .home-button-indicator {
      path {
        fill: var(--primary-contrast-color);
      }
    }

    @media (hover: hover) {
      &:hover {
        box-shadow: 0 1px 14px rgba(0, 0, 0, 0.3) !important;
      }
    }
  }
}