.news {
  height: calc(100vh - var(--header-tabbar-height));
  overflow: auto;
  padding: 18px;
  animation: menuFadeIn;
  animation-duration: 0.45s;

  .news-card {
    max-width: 500px;
    margin: auto;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .no-news {
    text-align: center;
    padding: 20px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--secondary-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .title {
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: var(--primary-text-color);
      margin-bottom: 8px;
    }

    .description {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: var(--secondary-text-color);
    }

    .no-news-yet {
      width: 32px;
      height: auto;
      margin-bottom: 20px;
      fill: var(--primary-text-color);
    }
  }

  .load-more {
    cursor: pointer;
    margin: auto;
    text-align: center;
    font-size: 14px;
    line-height: 32px;
    font-weight: normal;
    font-family: Inter, sans-serif;
    color: var(--primary-color);
  }
}