.search-card {
    overflow: hidden;
    border-radius: var(--border-radius-60);
    background-color: var(--background-color);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.06) 0px 2px 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    animation: fadeIn;
    animation-duration: 0.45s;
    padding: 8px;

    &-topbar {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 12px;
        background-color: var(--background-color-darker);
        border-radius: var(--border-radius-50);

        &-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: var(--primary-text-color);
        }

        &-icon {
            width: 14px;

            path {
                fill: var(--primary-text-color);
            }
        }

        &:hover {
            background-color: var(--primary-color-11);

            .search-card-topbar-icon,
            .search-card-topbar-title {
                color: var(--primary-color);

                path {
                    fill: var(--primary-color);
                }
            }
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        margin-top: 8px;

        &-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: var(--primary-text-color);
            border-radius: var(--border-radius-50);

            &-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 10px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }

            svg {
                width: 12px;
                height: 12px;
                flex-shrink: 0;

                path {
                    fill: var(--primary-color);
                }
            }

            &:hover {
                background-color: var(--primary-color-11);
                color: var(--primary-color);

                .search-card-list-item-title {
                    color: var(--primary-color);
                }
            }
        }
    }
}