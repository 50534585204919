.bot-card {
    display: flex;
    margin-bottom: 5px;
    justify-content: flex-start;
    align-items: flex-end;

    &-avatar {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        margin-bottom: 6px;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
        }

        svg {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
        }
    }

    &-avatar-placeholder {
        width: 32px;
        height: 32px;
        margin-bottom: 6px;
        flex-shrink: 0;
    }

    &-content {
        margin-left: 8px;
        background-color: var(--background-color);
        box-sizing: border-box;
        border-radius: var(--border-radius-60);
        padding: 17px 20px;
        color: var(--primary-text-color);
        font-size: 15px;
        font-weight: 400;
        line-height: 1.4;
        max-width: calc(100% - 40px);
        box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
    }
}

.salut {
    color: var(--primary-text-color);
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;

    span {
        margin-right: 3px;
    }
}