.home-page {
  margin: auto;
  z-index: 2;
  position: relative;
  width: 100%;
  height: calc(100vh - var(--tabbar-height));
  overflow: hidden;

  .operator-info-team-image {
    margin-left: 40px;
    margin-top: 0px;
    margin-bottom: 20px;

    img {
      display: inline-block;
      width: 39px;
      height: 39px;
      border-radius: 100%;
      object-fit: cover;
      overflow: hidden;
      margin-left: -2px;
      background-color: var(--background-color);

      &:not(:first-of-type) {
        -webkit-mask: radial-gradient(
          circle at -16px center,
          transparent 21px,
          #fff 21px
        );
      }
    }

    div {
      display: inline-block;
      width: 39px;
      height: 39px;
      border-radius: 100%;
      object-fit: cover;
      overflow: hidden;
      margin-left: -2px;
      background-color: var(--header-contrast-color-33);

      &:not(:first-of-type) {
        -webkit-mask: radial-gradient(
          circle at -16px center,
          transparent 21px,
          #fff 21px
        );
      }
    }
  }

  &-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: -1;
    background-color: var(--background-color);

    #bgrect {
      fill: var(--background-color);
    }

    #color2 {
      fill: var(--header-color);
    }

    #color1 {
      fill: var(--header-color-2);
    }

    #color3 {
      fill: var(--header-color-3);
    }

    #color-grad-1 {
      stop-color: var(--header-color-2);
    }

    #color-grad-2 {
      stop-color: var(--header-color);
    }

    #bg-class-1,
    #bg-class-2 {
      stop-color: var(--background-color);
    }

    &--image {
      height: 100%;
      background-position: top center;
      background-size: cover;
      object-fit: cover;
    }
  }

  &--blur {
    .home-page-inner {
      backdrop-filter: blur(30px);
    }
  }

  &-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    &-content {
      width: 100%;
      max-width: 100%;
      display: block;
      padding-top: 65px;
      padding-bottom: 10px;
      overflow-x: hidden;
      overflow-y: auto;

      &--news {
        padding-top: 160px;
      }
    }

    .powered-by {
      animation: menuFadeIn;
      animation-duration: 0.45s;
      padding: 0px;
      margin-top: 30px;
      margin-left: 75px;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-header {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 35px 35px 0px;
    z-index: 2;

    &-logo {
      object-fit: contain;
      width: auto;
      max-width: 200px;
      height: 34px;

      path {
        fill: var(--header-contrast-color);
      }
    }

    [dir="rtl"] &-close {
      right: auto !important;
      left: 15px !important;
    }

    &-close {
      margin-left: 0px;
      position: absolute;
      right: 15px;
      top: 32px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      svg {
        height: 22px;
      }
    }
  }

  &-bot {
    padding: 0px 35px;
  }

  &-content {
    padding: 0px 35px;
    padding-top: 10px;
    padding-left: 75px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-content-news {
    animation: menuFadeIn;
    animation-duration: 0.45s;
    padding: 0px 35px;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;

    .news-card {
      width: 100%;
    }
  }
}

@keyframes grow-shrink2 {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.35);
  }
}

@keyframes grow-shrink3 {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
  }

  50% {
    transform: scale(1.3) rotate(20deg);
  }
}

.bg-animating {
  .path-class2 {
    animation: grow-shrink2 7s infinite;
    transform-origin: 50% 50%;
  }

  .path-class3 {
    animation: grow-shrink3 6s infinite;
    transform-origin: 50% 50%;
  }
}

.bot-card-content {
  .conversation-item {
    padding: 0px !important;
    margin-bottom: 0px !important;

    &:hover {
      background-color: var(--background-color) !important;
    }

    .conversation-item-avatar {
      display: none !important;
    }

    .conversation-item--unread .conversation-item-unreaddot {
      position: absolute;
      top: -10px;
      right: -10px;
    }

    .conversation-item-title {
      color: var(--primary-text-color);
      font-size: 15px;
      font-weight: 400;
      line-height: 1.4;
    }

    .conversation-item-labels {
      max-width: 100%;
      padding-left: 0px !important;
    }

    .conversation-item--inner {
      border-bottom: none !important;
      padding-bottom: 0px !important;
    }
  }

  .checklist-card {
    box-shadow: none !important;
    border: none !important;
    padding: 0px !important;
    border-radius: 0px !important;

    .checklist-card-content {
      padding: 0px !important;
    }
  }
}

.reply-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: var(--primary-text-color);
    margin-right: 3px;
  }

  svg {
    height: 15px;
    width: 15px;

    path {
      fill: var(--primary-color);
    }
  }

  &:hover {
    span {
      color: var(--primary-color);
    }

    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }
}
