.audio-recorder-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-height: 46px;

  &--recording {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 7px;
    padding-right: 0px;

    .audio-recorder-actions {
      display: flex;
      flex-direction: row;
      align-items: center;

      .recording-text {
        color: var(--secondary-text-color);
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  &--recording-paused {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 7px;

    .audio-recorder-player {
      height: 32px;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .audio-player {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .audio-recorder-icon {
    height: 16px;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      path {
        fill: var(--primary-color);
      }
    }
  }

  .audio-pause-icon {
    path {
      fill: red;
    }
  }

  .recording-pulse {
    animation: pulse 1s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
