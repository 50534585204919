.conversation-messages-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 80px;

  .conversation-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .conversation-error-icon {
      width: 44px;
      height: 44px;
      margin-bottom: 8px;
    }

    .conversation-error-button {
      background-color: var(--primary-color);
      color: var(--primary-contrast-color);
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: var(--border-radius-50);
      min-height: 38px;
      line-height: 18px;
      padding: 10px 14px;
      margin: 2px;
      font-size: 14px;
      font-weight: normal;
      text-align: left;
    }

    text-align: center;
    font-size: 14px;
    color: var(--text-color);
    margin-top: 20px;
  }
}
