.form-duplicates {
  display: flex;
  flex-direction: column;

  .duplicate-line {
    width: 100%;
    height: 1px;
    background-color: var(--primary-color-11);
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .form-duplicate {
    min-height: 43px;
    min-width: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 13px;
    padding-left: 8px;
    border-radius: 10px;

    &__submiticon {
      height: 27px;
      width: 40px;
      flex-shrink: 0;
      background: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin-right: 8px;
      font-size: 12px;
      background-color: var(--primary-color-11);
      color: var(--primary-color);

      svg {
        fill: var(--primary-color) !important;
        width: 10px !important;
      }
    }

    &__upvotes {
      width: 40px;
      height: 27px;
      flex-shrink: 0;
      background: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin-right: 8px;
      font-size: 12px;
      background-color: var(--primary-color-11);
      color: var(--primary-color);

      svg {
        width: 7px;
        margin-right: 3px;
        fill: var(--primary-color);
      }
    }

    &__title {
      color: var(--primary-text-color);
      font-weight: 400;
      flex-grow: 1;
      font-size: 14px;
      line-height: 1.5;
      margin-right: 10px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      display: block;
      color: var(--primary-color);
      width: 7px;
      flex-shrink: 0;
    }

    &:hover {
      background-color: var(--primary-color-11);

      .form-duplicate {
        &__title {
          color: var(--primary-color);
        }

        &__upvotes {
          background-color: var(--primary-color);
          color: var(--background-color);

          svg {
            fill: var(--background-color);
          }
        }

        &__submiticon {
          background-color: var(--primary-color);
          color: var(--background-color);

          svg {
            fill: var(--background-color) !important;
          }
        }
      }
    }
  }
}
