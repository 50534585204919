.bot-action-text-upload {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;

  &-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 8px;
    color: var(--primary-text-color);
  }

  &-innercontainer {
    width: 100%;
    position: relative;
    


    &:focus,
    &:hover {
      select {
        outline: none !important;
        border: 1px solid var(--primary-color-AA) !important;
      }

      .caret {
        fill: var(--primary-color) !important;
      }
    }
  }
}
