.widget-app-container {
  box-sizing: border-box;
  margin-bottom: 12px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;

    &-title {
      font-size: 14px;
      font-weight: 500;
      color: var(--primary-text-color);
    }

    &-action {
      font-size: 14px;
      font-weight: 500;
      color: var(--primary-color);
      cursor: pointer;
    }
  }

  &-body {
    padding: 0px;

    .news-card {
      border: none;
      border-radius: var(--border-radius-60);

      .news-card-content {
        padding: 17px 20px;
      }
    }

    .widget-feature-requests {
      padding: 20px;
    }
  }
}

.widget-feature-requests {
  border-top: 1px solid var(--border-color-55);
  padding-top: 20px;
}