.ai-action-button {
    border: none;
    outline: none;
    cursor: pointer;
    align-items: center;
    display: flex;
    background-color: var(--primary-color);
    color: var(--primary-contrast-color);
    border-radius: var(--border-radius-50);
    min-height: 38px;
    line-height: 18px;
    padding: 10px 14px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    box-shadow: 1px 1px 10px 0px var(--primary-text-color-11);

    &:hover {
        background-color: var(--primary-color-AA);
        color: var(--primary-contrast-color);
        box-shadow: 1px 1px 10px 0px var(--primary-text-color-11);
    }
    
    svg {
        margin-left: 8px;
        color: var(--primary-contrast-color);
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        animation: checkFadeIn 0.5s;

        path {
            fill: var(--primary-contrast-color);
        }
    }
}

@keyframes checkFadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }