.news-content-body {
  .collaboration-cursor {
    &__caret {
      border-right: 1px solid black;
      border-left: 1px solid black;
      pointer-events: none;
      margin-left: -1px;
      margin-right: -1px;
      position: relative;
      word-break: normal;
    }

    &__label {
      border-radius: 0.25rem;
      border-top-left-radius: 0;
      color: black;
      font-size: 0.75rem;
      font-weight: 600;
      left: -1px;
      line-height: 1;
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
      padding-left: 0.375rem;
      padding-right: 0.375rem;
      position: absolute;
      top: -1.4em;
      user-select: none;
      white-space: nowrap;
    }
  }
}
