.chat-message {
  padding: 24px;
  padding-top: 0px;
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .attachment-link {
    margin-top: 5px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 100%;
    cursor: pointer;

    svg {
      margin-right: 3px;
      width: 14px;
      height: 14px;
      fill: var(--primary-color);
    }
  }

  .ai-audio-attachment {
    audio {
      width: 100%;
      height: 44px;
      background-color: var(--background-color-darker);
      border-radius: var(--border-radius-50);
      border: 1px solid var(--border-color-55);
      margin-top: 10px;
    }
  }

  .attachment-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-top: 5px;
    border-radius: var(--border-radius-25);
    cursor: pointer;
  }

  .message-container {
    margin-left: 44px;
    max-width: calc(93% - 32px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .message {
      background-color: var(--background-color-darker);
      box-sizing: border-box;
      border-radius: var(--border-radius-60);
      padding: 17px 20px;
      color: var(--primary-text-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      max-width: 100%;

      .message-text {
        overflow-wrap: break-word;
        line-height: 1.4;

        > :first-child {
          margin-top: 0px !important;
        }

        a,
        p {
          color: var(--primary-text-color);
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          overflow-wrap: break-word;
          word-wrap: break-word;
          -ms-word-break: break-all;
          word-break: break-all;
          word-break: break-word;
        }

        p:empty:before {
          content: " ";
          white-space: pre;
        }

        a {
          color: var(--primary-color);
          text-decoration: underline;
        }

        ul {
          padding-left: 15px;
          margin-top: 10px;
          margin-bottom: 10px;

          li {
            padding-top: 3px;
            padding-bottom: 3px;
            line-height: 1.4;
          }
        }

        .linktype-button {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        img {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 500px;
          object-fit: contain;
          margin-top: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          border-radius: var(--border-radius-25);
        }

        h1,
        h2 {
          margin-top: 10px;
          margin-bottom: 3px;
          font-weight: bold;
          font-size: 18px;
          line-height: 1.4;
          color: var(--primary-text-color);

          span {
            font-size: 18px;
            line-height: 1.4;
            font-weight: bold;
            color: var(--primary-text-color);
          }
        }

        h3,
        h4,
        h5,
        h6 {
          margin-top: 10px;
          margin-bottom: 3px;
          font-weight: bold;
          font-size: 15px;
          line-height: 1.4;
          color: var(--primary-text-color);

          span {
            font-size: 15px;
            line-height: 1.4;
            font-weight: bold;
            color: var(--primary-text-color);
          }
        }

        ol,
        ul {
          padding-left: 15px;
          margin-top: 10px;
          margin-bottom: 10px;

          li {
            padding-top: 3px;
            padding-bottom: 3px;
            line-height: 1.4;
          }
        }

        iframe {
          width: 100%;
          border: 0px !important;
          height: 300px;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .iframe-wrapper {
          position: relative;
          padding-bottom: 62.5%;
          height: 0;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          margin-top: 3px;
          margin-bottom: 3px;
        }

        code {
          background: none;
          color: inherit;
          font-size: 0.8rem;
          padding: 0;
          font-family: "JetBrainsMono", monospace;
          background-color: var(--border-color-55);
          color: var(--primary-text-color);
          padding: 2px 4px;
          border-radius: 5px;
        }

        pre {
          background: #0d0d0d;
          border-radius: 0.5rem;
          color: #fff;
          font-family: "JetBrainsMono", monospace;
          padding: 0.75rem 1rem;
          font-size: 14px;
          line-height: 18px;
          max-width: 100%;
          overflow: auto;
          margin-top: 10px;
          margin-bottom: 10px;

          code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
            font-family: "JetBrainsMono", monospace;
          }
        }

        .editor-button {
          background-color: var(--button-color);
          color: var(--primary-contrast-color);
          caret-color: var(--primary-contrast-color);
          width: fit-content;
          padding: 10px 20px;
          border-radius: 5px;
          text-decoration: none;
          margin-top: 20px;
          margin-bottom: 20px;

          a {
            color: var(--primary-contrast-color);
            font-weight: 500 !important;
            font-size: 14px !important;
            text-decoration: none !important;
          }

          span {
            &::selection {
              background-color: rgba(
                $color: var(--primary-contrast-color),
                $alpha: 0.5
              );
            }
          }

          &::selection {
            background-color: rgba(
              $color: var(--primary-contrast-color),
              $alpha: 0.5
            );
          }
        }
      }

      .message-form-item {
        margin-bottom: 8px;

        &-title {
          font-size: 14px;
          line-height: 1.4;
          opacity: 0.6;
        }

        &-value {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          word-wrap: break-word;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    .chat-message-loading {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--background-color-darker);
      border-radius: var(--border-radius-60);
      padding: 80px 80px;
      margin-top: 3px;
    }

    .chat-message-image {
      margin-top: 3px;

      img,
      video {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 260px;
        border-radius: var(--border-radius-50);
        border: 1px solid var(--border-color-33);
      }

      img {
        cursor: pointer;
      }
    }
  }

  &--typing {
    .avatar {
      margin-bottom: 5px;
    }
  }

  &--featurerequest-duplicates {
    .message-container {
      width: 100%;
    }

    .message {
      background-color: var(--background-color) !important;
      border: 1px solid var(--border-color-55);
      padding: 8px !important;
      width: 100%;
      box-shadow: 0px 0px 10px 0px rgba($color: #000000, $alpha: 0.07);
    }
  }

  &--aibot {
    .avatar {
      border-radius: var(--border-radius-50);

      svg {
        border-radius: var(--border-radius-50);
      }
    }
  }

  &--last-of-group {
    padding-bottom: 16px;

    .message-container {
      margin-left: 12px;
    }

    .avatar {
      display: flex;
    }
  }

  &--creator {
    justify-content: flex-end;

    .message-container {
      margin-left: 0px;
      align-items: flex-end;

      .message {
        background-color: var(--primary-color);
        color: var(--primary-contrast-color);
        border-radius: var(--border-radius-60);

        .attachment-link,
        .attachment-link span,
        .attachment-link a,
        .message-text,
        .message-text a,
        .message-text p,
        p {
          color: var(--primary-contrast-color);
        }

        .attachment-link svg {
          fill: var(--primary-contrast-color);
        }
      }
    }

    .avatar {
      display: none;
    }
  }
}

.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 5px;
  background-color: var(--primary-text-color);
  color: var(--primary-text-color);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: var(--primary-text-color);
  color: var(--primary-text-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: var(--primary-text-color);
  color: var(--primary-text-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--primary-text-color-99);
  }

  50%,
  100% {
    background-color: var(--primary-text-color-11);
  }
}

.message-date {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: var(--secondary-text-color);
}

.bot-input-reply {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 8px;
    color: var(--primary-text-color);
  }

  &-rating-response {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;

    &-option {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 34px;
      transition: all 0.2s ease-in-out;
      cursor: not-allowed;
      transform: none;
      filter: grayscale(100%);

      &--selected {
        cursor: pointer;
        filter: none;
        transform: scale(1.2);
      }
    }
  }

  &-response {
    width: 100%;
    font-size: 14px;
    line-height: 1.3;
    appearance: none;
    padding: 11px 12px;
    word-break: break-word;
    outline: 0;
    flex-grow: 1;
    color: var(--primary-text-color);
    border: 1px solid var(--border-color-33);
    background-color: var(--background-color);
    border-radius: var(--border-radius-50);
    box-sizing: border-box;
    font-weight: 400;
    cursor: not-allowed;
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.01);
  }

  &-check {
    position: absolute;
    bottom: 13px;
    right: 15px;
    width: 15px;
    height: auto;

    path {
      fill: #198754 !important;
    }
  }
}

.link-sources {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -17px;
  padding: 12px 20px;
  margin-top: 0px;
  box-sizing: border-box;
  border-radius: 0px 0px var(--border-radius-50) var(--border-radius-50);
  color: var(--primary-text-color);

  &-header {
    margin-bottom: 0px;
    font-weight: 600;
    padding-bottom: 5px;
    padding-top: 6px;
  }

  &-links {
    display: flex;
    flex-direction: column;

    .link-source {
      min-height: 28px;
      min-width: 0px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0px;

      svg {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }

      &-button {
        margin-bottom: 2px;
      }

      &--arrow {
        margin-left: 8px;
        display: block;
        color: var(--secondary-text-color);
        font-size: 12px;
      }

      &--submiticon {
        height: 27px;
        width: 40px;
        flex-shrink: 0;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        margin-right: 8px;
        font-size: 12px;
        background-color: var(--primary-color-11);
        color: var(--primary-color);

        svg {
          color: var(--primary-color) !important;
        }
      }

      &--upvotes {
        width: 40px;
        height: 27px;
        flex-shrink: 0;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        margin-right: 8px;
        font-size: 11px;
        background-color: var(--primary-color-11);
        color: var(--primary-color);

        svg {
          width: 7px;
          margin-right: 3px;
          fill: var(--primary-color);
        }
      }

      &--title {
        color: var(--secondary-text-color);
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        .link-source {
          &--title {
            color: var(--primary-color) !important;
          }

          &--arrow {
            fill: var(--primary-color) !important;
            color: var(--primary-text-color);
          }

          &--upvotes {
            background-color: var(--primary-color);
            color: var(--background-color);

            svg {
              fill: var(--background-color);
            }
          }

          &--submiticon {
            background-color: var(--primary-color);
            color: var(--background-color);

            svg {
              color: var(--background-color) !important;
            }
          }
        }
      }
    }
  }
}

.answer-bot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--secondary-text-color);
  font-size: 13px;
  font-weight: 500;
  margin-top: 17px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -17px;
  padding: 11px 20px;
  border-top: 1px solid var(--border-color-55);

  &-tag {
    display: inline-block;
    margin-right: 3px;
    padding: 3px 4px;
    border-radius: 4px;
    background-color: var(--secondary-text-color);
    color: var(--background-color);
    font-size: 11px;
    font-weight: 700;
  }

  &-feedback {
    display: flex;
    cursor: pointer;
    gap: 15px;

    &-action {
      height: 17px;
      fill: var(--secondary-text-color);

      &:hover {
        fill: var(--primary-color);
      }

      &--active {
        fill: var(--primary-color);
      }
    }
  }
}

.scheduled {
  &-title {
    font-size: 14px !important;
    color: var(--primary-text-color);
  }

  &-date {
    margin-top: 8px;
    color: var(--secondary-text-color);
    font-size: 14px;
    font-weight: normal;
  }

  &-user {
    margin-top: 3px;
    color: var(--secondary-text-color);
    font-size: 14px;
    font-weight: normal;
  }
}

.ai-action-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 0px;
}

.chat-message-ai-action {
  padding-bottom: 10px;

  .message {
    background-color: var(--background-color) !important;
    box-sizing: border-box;
    border-radius: var(--border-radius-50);
    padding: 18px 20px;
    color: var(--primary-text-color);
    border-top: 2px solid var(--primary-color-55);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    max-width: 100%;
    width: 100%;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  }
}

.message-date-error {
  svg {
    width: 10px;
    height: 10px;
    fill: red;
    object-fit: contain;
    margin-right: 5px;
  }
}