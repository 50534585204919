.icon-button {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: var(--border-radius-60);
  -webkit-tap-highlight-color: transparent;
  position: relative;

  svg path {
    fill: var(--header-contrast-color);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--header-contrast-color);

      svg path {
        fill: var(--header-color);
      }
    }
  }

  &--unread {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #da0e07;
    position: absolute;
    right: 2px;
    top: 2px;
    color: #fff;
    font-weight: 600;
    font-size: 8px;
    text-align: center;
    line-height: 15px;
  }
}

.icon-button-svg {
  width: 20px;
  height: 20px;
}

[dir="rtl"] .icon-button {
  transform: scaleX(-1);
}