.news-content-body {
  p {
    line-height: 1.625;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  & > p {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.875rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
  }

  h1,
  h2,
  h3 {
    margin-top: 3rem;
  }

  h4,
  h5,
  h6 {
    margin-top: 2rem;
  }

  mark {
    background-color: #ef4444;
    border-radius: 0.125rem;
    text-decoration: none;
    padding: 0.25rem 0;
  }

  & img {
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  [data-type="horizontalRule"] {
    margin: 0px;
    padding: 1rem 0;
  }

  hr {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.8);
    margin: 10px 0px;
  }
  
  [data-type="emoji"] {
    display: inline-block;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 4px !important;

    img {
      width: 1em;
      height: 1em;
      object-fit: cover;
      display: block;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}
