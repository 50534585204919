.widget-header {
  background-color: transparent;
  background: linear-gradient(
    180deg,
    var(--header-color) 0%,
    var(--header-color) 65px,
    var(--background-color) 65px,
    var(--background-color) 100%
  );

  &--shadow {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    max-height: 380px;
    background: linear-gradient(
      130deg,
      var(--header-dark-color) 0%,
      var(--header-color) 100%
    );
    z-index: 1;
    animation: bgFadeOut 300ms both cubic-bezier(0.65, 0.03, 0.45, 0.9);
  }

  &--bg &--shadow {
    animation: bgFadeIn 300ms both cubic-bezier(0.65, 0.03, 0.45, 0.9);
  }

  &--bgfirst &--shadow {
    animation: bgFadeIn 0ms both cubic-bezier(0.65, 0.03, 0.45, 0.9);
  }

  &--bglarge &--shadow {
    animation: bgFadeInLarge 300ms both cubic-bezier(0.65, 0.03, 0.45, 0.9);
  }

  &--bg &--shadow,
  &--bgfirst &--shadow {
    &::before {
      content: " ";
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 100%;
      max-height: 380px;
      background: linear-gradient(
        120deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.1) 45%,
        transparent 60%
      );
    }

    &::after {
      content: " ";
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 100%;
      max-height: 380px;
      background: linear-gradient(
        180deg,
        transparent 60%,
        var(--background-color-1A) 70%,
        var(--background-color) 100%
      );
    }
  }

  &--nogradient {
    background: var(--header-color) !important;
    background-color: var(--header-color) !important;

    &::before {
      display: none !important;
    }
  }

  &--nofade {
    max-height: 310px;

    &::after {
      display: none !important;
    }
  }

  &-operator-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0px 10px;
    max-width: calc(100% - 80px);

    .operator-info-content-image-loading {
      min-width: 32px;
      height: 32px;
      margin-right: 10px;
      border-radius: 100%;
      flex-shrink: 0;
      object-fit: cover;
      background-color: var(--header-contrast-color-33);
    }

    .operator-info-content-loading {
      width: 100%;
      display: flex;
      flex-direction: column;

      .operator-info-content-title-loading {
        width: 100%;
        max-width: 70px;
        height: 8px;
        border-radius: 4px;
        background-color: var(--header-contrast-color-33);
      }

      .operator-info-content-subtitle-loading {
        width: 100%;
        max-width: 110px;
        height: 7px;
        margin-top: 8px;
        border-radius: 4px;
        opacity: 0.5;
        background-color: var(--header-contrast-color-33);
      }
    }

    .operator-info-team-image {
      min-width: 32px;
      height: 32px;
      margin-right: 10px;
      flex-shrink: 0;
      position: relative;

      img {
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 100%;
        object-fit: cover;
        border: 2px solid var(--header-color);
        background-color: var(--background-color);
      }

      img:nth-of-type(1) {
        position: absolute;
        top: -2px;
        left: -2px;
        z-index: 3;
      }

      img:nth-of-type(2) {
        position: absolute;
        top: -2px;
        left: 20px;
        z-index: 2;
      }

      img:nth-of-type(3) {
        position: absolute;
        top: -2px;
        left: 40px;
        z-index: 1;
      }
    }

    .operator-info-image {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      margin-right: 10px;
      overflow: hidden;
      flex-shrink: 0;

      img,
      svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      svg,
      img {
        #botbg {
          fill: var(--header-contrast-color) !important;
        }

        .botclip,
        #botclip {
          fill: var(--header-color) !important;
        }
      }
    }

    .operator-info-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: calc(100% - 80px);

      &-title {
        color: var(--header-contrast-color);
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &-subtitle {
        color: var(--header-contrast-color);
        opacity: 0.6;
        font-size: 12px;
        line-height: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        margin-top: 2px;
        max-height: 25px;
      }
    }
  }

  &-sub {
    max-width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    animation: fadeInSub;
    animation-duration: 0.8s;
    position: relative;
    z-index: 2;
    position: relative;
    min-height: 65px;
    box-sizing: border-box;

    @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
      padding-left: max(env(safe-area-inset-left), 10px);
      padding-right: max(env(safe-area-inset-right), 10px);
    }

    &-ticket {
      background-color: var(--header-color) !important;
    }

    &--bot {
      background-color: var(--background-color);
      box-shadow: 1px 1px var(--border-color-33);
      transition: all 0.25s ease-in-out;

      .icon-button {
        transition: all 0.25s ease-in-out;

        svg path {
          fill: var(--primary-text-color);
        }

        @media (hover: hover) {
          &:hover {
            background-color: var(--primary-color-22) !important;

            svg path {
              fill: var(--primary-color);
            }
          }
        }
      }

      .operator-info-content-title {
        color: var(--primary-text-color);
        transition: color 0.25s ease-in-out;
      }

      .operator-info-content-subtitle {
        color: var(--secondary-text-color);
        transition: color 0.25s ease-in-out;
      }

      .operator-info-image {
        border-radius: var(--border-radius-50);
        width: 32px;
        height: 32px;
      }
    }

    &-with-center {
      padding: 0px 8px;
      padding-left: calc(env(safe-area-inset-left) + 8px);
      padding-right: calc(env(safe-area-inset-right) + 8px);
    }

    &-center {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &-title {
      margin-top: 0;
      line-height: 22px;
      font-size: 17px;
      font-weight: 600;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: none;
      text-align: center;
      color: var(--header-contrast-color);
      padding-left: 5px;
      padding-right: 5px;

      &--no-back {
        margin-left: 40px;
      }
    }

    &--hide-back-button {
      justify-content: space-between;
    }

    .close-spacer {
      width: 40px;
      min-width: 40px;
    }

    &--extended {
      background-color: var(--background-color);
      transition: all 0.2s ease-in-out;

      .widget-header-sub-title {
        opacity: 0;
        color: var(--primary-text-color);
        line-height: 21px;
        font-size: 15px;
        font-weight: 700;
        padding-left: 10px;
        text-align: left;
        transition: all 0.45s ease-in-out;
      }

      &--scrolled {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 14px;

        .widget-header-sub-title {
          opacity: 1;
        }
      }

      .icon-button {
        svg {
          path {
            fill: var(--primary-color);
          }
        }

        &:hover {
          background-color: var(--primary-color-20);
        }
      }
    }
  }

  &-main {
    padding: 35px;
    padding-bottom: 85px;
    animation: fadeIn;
    animation-duration: 0.8s;
    z-index: 2;
    position: relative;
    max-width: 600px;
    margin: auto;

    &-logo-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-height: 41px;

      .operator-info-team-image {
        margin-left: 0px;
        margin-top: 65px;

        img {
          display: inline-block;
          width: 39px;
          height: 39px;
          border-radius: 100%;
          object-fit: cover;
          overflow: hidden;
          margin-left: -2px;
          background-color: var(--background-color);

          &:not(:first-of-type) {
            -webkit-mask: radial-gradient(
              circle at -16px center,
              transparent 21px,
              #fff 21px
            );
          }
        }

        div {
          display: inline-block;
          width: 39px;
          height: 39px;
          border-radius: 100%;
          object-fit: cover;
          overflow: hidden;
          margin-left: -2px;
          background-color: var(--header-contrast-color-33);

          &:not(:first-of-type) {
            -webkit-mask: radial-gradient(
              circle at -16px center,
              transparent 21px,
              #fff 21px
            );
          }
        }
      }
    }

    &-logo {
      object-fit: contain;
      width: auto;
      max-width: 200px;
      height: 34px;

      path {
        fill: var(--header-contrast-color);
      }
    }

    &-title {
      margin-top: 17px;
      display: flex;
      justify-content: flex-start;
      text-shadow: rgba(255, 255, 255, 0.3) 0px 0px 30px;

      &-name {
        text-transform: capitalize;
      }

      span {
        font-size: 27px;
        line-height: 30px;
        font-weight: 700;
        margin-right: 6px;
        color: var(--header-contrast-color);
        opacity: 0.7;
        -webkit-font-smoothing: antialiased;
      }

      .hand {
        opacity: 1;
      }
    }

    &-description {
      font-size: 27px;
      line-height: 30px;
      font-weight: 700;
      margin-top: 4px;
      margin-bottom: 5px;
      opacity: 1;
      color: var(--header-contrast-color);
      text-shadow: rgba(255, 255, 255, 0.3) 0px 0px 30px;
    }

    &-close {
      margin-left: 0px;
      position: absolute;
      right: 15px;
      top: 32px;
    }

    &-hideteam {
      .widget-header-main-title {
        margin-top: 70px;
      }
    }
  }

  .header-exit-active {
    > * {
      animation: headerFadeOut 300ms both ease-out;
    }
  }

  .header-enter-active,
  .header-enter-done {
    > * {
      animation: headerFadeIn 500ms both ease-in;
    }
  }

  .widget-header-main-close {
    z-index: 99;
  }

  &--scrolled {
    .widget-header-main-close {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }
}

@keyframes headerFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes headerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bgFadeInLarge {
  0% {
    transform: translateY(calc(-100% + 65px));
  }

  100% {
    transform: translateY(calc(-100% + 110px));
  }
}

@keyframes bgFadeIn {
  0% {
    transform: translateY(calc(-100% + 65px));
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes bgFadeOut {
  0% {
    transform: translateY(0%);
  }

  60% {
    transform: translateY(calc(-100% + 65px));
    opacity: 1;
  }

  100% {
    transform: translateY(calc(-100% + 65px));
    opacity: 0;
  }
}

[dir="rtl"] .widget-header-sub-title {
  margin-right: 8px;
  margin-left: 0px !important;
}

.main-app--survey,
.main-app--survey-full {
  .widget-header {
    background-color: transparent;

    &--shadow {
      display: none;
    }

    &-sub {
      box-shadow: none !important;
      padding: 0px !important;

      &::before {
        display: none;
      }

      &-title {
        display: none;
      }
    }

    .icon-button {
      position: fixed;
      top: 10px;
      right: 10px;
      z-index: 99;

      svg path {
        fill: var(--primary-color);
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--primary-color);

          svg path {
            fill: var(--primary-contrast-color);
          }
        }
      }
    }
  }

  .widget-header-sub {
    padding: 8px;
    padding-left: 10px;
    box-shadow: none !important;
  }
}

.main-app--survey .widget-header-sub,
.main-app--survey-full .widget-header-sub {
  min-height: 0px;
}

.main-app--survey {
  .widget-header {
    .icon-button {
      position: fixed;
      top: 8px;
      right: 8px;
      z-index: 99;
      width: 28px;
      min-width: 28px;
      height: 28px;
    }
  }
}

.widget-header-sub-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.widget-header-sub-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  margin-top: 20px;

  .widget-header-operator-info {
    max-width: 100%;
    width: 100%;
  }
}

.widget-header-sub-newtitle {
  color: var(--header-contrast-color);
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.widget-ticket-status {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .widget-ticket-status-item {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 5px);

    &-bar {
      height: 5px;
      width: 100%;
      opacity: 0.4;
      background-color: var(--header-contrast-color);
      border-radius: var(--border-radius-15);
    }

    &-title {
      color: var(--header-contrast-color);
      opacity: 0.4;
      font-size: 13px;
      line-height: 1.2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      margin-top: 3px;
      font-weight: 500;
      text-align: center;
    }

    &--active {
      .widget-ticket-status-item-title {
        opacity: 1;
      }

      .widget-ticket-status-item-bar {
        opacity: 1;
      }
    }
  }
}

[dir="rtl"] {
  .widget-header-main-close {
    right: initial !important;
    left: 15px !important;
  }

  .operator-info-team-image {
    display: flex;
    flex-direction: row-reverse;
  }

  .powered-by {
    display: none !important;
  }

  .operator-info-image {
    margin-left: 10px;
    margin-right: 0px;
  }

  .chat-message .message-container {
    margin-left: 0px;
    margin-right: 44px;
  }

  .chat-message--last-of-group .message-container {
    margin-left: 0px;
    margin-right: 12px;
  }

  .chat-message-composer textarea {
    padding-left: 0px;
    padding-right: 25px;
  }

  .operator-info-team-image {
    display: none !important;
  }
}
