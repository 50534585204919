.conversation {
  height: calc(100vh - var(--header-height));
  overflow: auto;

  &--ticket {
    height: calc(100vh - calc(var(--header-height) + 41px));
  }

  &-messages {
    overflow: auto;
    animation: menuFadeIn;
    animation-duration: 0.45s;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 24px;
    padding-bottom: 8px;

    @supports (padding: env(safe-area-inset-left)) {
      padding-left: env(safe-area-inset-left);
    }

    @supports (padding: env(safe-area-inset-right)) {
      padding-right: env(safe-area-inset-right);
    }

    &-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;

      .gleap-loading {
        width: 34px;
        height: 34px;
      }

      .gleap-loading div {
        width: 34px;
        height: 34px;
        border-width: 1.5px;
      }
    }
  }

  .conversation-ended {
    height: 140px;
    border-top: 1px solid var(--border-color-33);
    padding: 24px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background-color);

    &-label {
      font-size: 14px;
      color: var(--primary-text-color);
      text-align: center;
    }

    .action-button {
      margin-top: 15px;

      svg {
        margin-right: 0px;
        margin-left: 10px;
      }
    }
  }
}