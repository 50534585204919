.news-content-body {
  .tableWrapper {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  table {
    border-collapse: collapse;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-sizing: border-box;
    width: 100%;
  }

  table td,
  table th {
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100px;
    padding: 0.5rem;
    position: relative;
    text-align: left;
    vertical-align: top;
  }

  table td:first-of-type:not(a),
  table th:first-of-type:not(a) {
    margin-top: 0;
  }

  table td p,
  table th p {
    margin: 0;
  }

  table td p + p,
  table th p + p {
    margin-top: 0.75rem;
  }

  table th {
    font-weight: bold;
  }

  table .column-resize-handle {
    bottom: -2px;
    display: flex;
    pointer-events: none;
    position: absolute;
    right: -1px;
    top: 0;
    width: 2px;
  }

  table .column-resize-handle::before {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 1px;
    margin-left: 0.5rem;
    content: '';
    background-color: rgba(255, 255, 255, 0.2);
  }

  table {
    .is-empty::before {
      display: none !important;
    }
  }

  table .grip-column,
  table .grip-row {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 10;
  }

  table .grip-column {
    width: calc(100% + 1px);
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    height: 0.75rem;
    left: 0;
    margin-left: -1px;
    top: -0.75rem;
    border-color: rgba(255, 255, 255, 0.2);
  }

  table .grip-column:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  table .grip-column:hover::before {
    border-bottom: 2px dotted rgba(0, 0, 0, 0.6);
    border-color: rgba(0, 0, 0, 0.6);
    content: "";
    width: 10px;
  }

  table .grip-column.first {
    border-color: transparent;
    border-top-left-radius: 0.125rem;
  }

  table .grip-column.last {
    border-top-right-radius: 0.125rem;
  }

  table .grip-column.selected {
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }

  table .grip-column.selected::before {
    border-bottom: 2px dotted;
  }

  table .grip-row {
    height: calc(100% + 1px);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    left: -0.75rem;
    width: 0.75rem;
    top: 0;
    margin-top: -1px;
    border-color: rgba(255, 255, 255, 0.2);
  }

  table .grip-row:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  table .grip-row:hover::before {
    border-left: 2px dotted rgba(0, 0, 0, 0.6);
    border-color: rgba(0, 0, 0, 0.6);
    content: "";
    width: 10px;
  }

  table .grip-row.first {
    border-color: transparent;
    border-top-left-radius: 0.125rem;
  }

  table .grip-row.last {
    border-bottom-left-radius: 0.125rem;
  }

  table .grip-row.selected {
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }

  table .grip-row.selected::before {
    border-left: 2px dotted;
  }
}
