.loading01 {
  display: inline-block;
}

.loading01 span {
  animation-name: charKeyAnimation;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  color: var(--primary-text-color);
}

@keyframes charKeyAnimation {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.1;
  }
}
