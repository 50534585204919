@import '../../Components/tiptap/styles/index.css';

.news-details {
  height: 100vh;
  overflow: auto;
  background-color: var(--background-color);
  z-index: 2;
  position: relative;

  .news-details-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    .gleap-loading {
      width: 34px;
      height: 34px;
    }

    .gleap-loading div {
      width: 34px;
      height: 34px;
      border-width: 3px;
    }
  }

  .back-button {
    position: fixed;
    top: 10px;
    left: 8px;
    z-index: 12;

    @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
      left: 32px;
    }

    .icon-button {
      background-color: var(--background-color-99);
      border-radius: var(--border-radius-60);
      width: 44px;
      height: 44px;

      svg path {
        fill: var(--primary-color);
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--primary-color-22) !important;

          svg path {
            fill: var(--primary-color);
          }
        }
      }
    }
  }

  .close-button {
    position: fixed;
    top: 10px;
    right: 8px;
    z-index: 12;

    background-color: var(--background-color-99);
    border-radius: var(--border-radius-60);
    width: 44px;
    height: 44px;

    svg path {
      fill: var(--primary-color);
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--primary-color-22) !important;

        svg path {
          fill: var(--primary-color);
        }
      }
    }
  }

  .news-title {
    color: var(--primary-text-color);
    padding: 24px;
    padding-bottom: 0px;
    padding-top: 40px;
    font-size: 27px;
    line-height: 1.4;
    font-weight: 700;
    animation: menuFadeIn;
    animation-duration: 0.45s;
    max-width: 550px;
    margin: auto;

    @media only screen and (max-width: 660px) {
      font-size: 22px;
      padding-top: 20px;
    }
  }

  .news-header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background-color);

    span {
      transition: all 0.2s ease-in-out;
      opacity: 0;
      color: var(--primary-text-color);
      line-height: 21px;
      font-size: 15px;
      font-weight: 700;
      padding-left: 10px;
      text-align: left;
      display: block;
      padding-left: 60px;
      max-width: calc(100% - 60px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--placeholder {
      height: 60px;
    }

    &--shadow {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 14px;
    }

    &--title {
      span {
        opacity: 1;
      }
    }
  }

  .news-content-skeleton {
    padding: 24px;
    padding-bottom: 0px;
    padding-top: 40px;
    animation: menuFadeIn;
    animation-duration: 0.45s;
    max-width: 550px;
    margin: auto;
  }

  .news-content-image {
    width: 100%;
    height: auto;
    max-height: 330px;
    object-fit: cover;
  }

  .news-content {
    max-width: 550px;
    margin: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (min-width: 660px) {
      padding-bottom: 60px;
    }

    &-meta {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      img {
        width: 25px;
        height: 25px;
        margin-right: 12px;
        border-radius: 100%;
        object-fit: cover;
      }

      span {
        color: var(--secondary-text-color);
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      width: 100%;

      > :first-child {
        margin-top: 0px !important;
      }

      p {
        min-height: 14px;
      }

      a,
      p,
      span {
        color: var(--primary-text-color);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }

      strong * {
        font-weight: 600;
      }

      a,
      a span {
        color: var(--primary-color);
        text-decoration: underline;
      }

      ul {
        padding-left: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      ol {
        padding-left: 30px;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .linktype-button {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 500px;
        object-fit: contain;
        border-radius: 10px;
      }

      h1,
      h2 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 19px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--primary-text-color);

        span {
          font-size: 19px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);
        }
      }

      h3 {
        margin-top: 15px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 17px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--primary-text-color);

        span {
          font-size: 17px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);
        }
      }

      iframe {
        width: 100%;
        border: 0px !important;
        height: 300px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .iframe-wrapper {
        position: relative;
        padding-bottom: 62.5%;
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      pre {
        background: #0d0d0d;
        border-radius: 0.5rem;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        font-size: 14px;
        line-height: 18px;
        max-width: 100%;
        overflow: auto;
        margin-top: 20px;
        margin-bottom: 20px;

        code {
          background: none;
          color: inherit;
          font-size: 0.8rem;
          padding: 0;
          font-family: "JetBrainsMono", monospace;
        }
      }
    }
  }

  code {
    font-size: 14px !important;
    line-height: 1.4;
    text-shadow: none !important;

    span {
      font-size: 14px !important;
      line-height: 1.4;
      color: white;
    }

    * {
      text-shadow: none !important;
    }
  }

  ul[data-type="taskList"] {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 4px 0px;

      p {
        margin: 0px;
        padding-left: 4px;
        line-height: 1.4;
      }
    }
  }

  code::selection {
    background: #444;
    color: white;
  }

  code span::selection {
    background: #444;
    color: white;
  }

  .is-empty::before {
    color: #6b7280;
    color: #9ca3af;
  }

  & > *:first-child,
  [data-type="column"] > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  .node-imageUpload {
    border-radius: 0.25rem;
    border-width: 2px;
    border-style: dotted;
    border-color: rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    border-color: #6b7280;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &:hover {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &:has(.is-active),
    &.has-focus {
      border-color: rgba(0, 0, 0, 0.4);
    }
  }

  [data-type="columns"] {
    &.has-focus [data-type="column"],
    &:hover [data-type="column"] {
      border-color: #d1d5db;
      border-color: #374151;
    }

    [data-type="column"].has-focus {
      border-color: #9ca3af;
      border-color: #4b5563;
    }
  }

  .node-imageBlock {
    & img {
      border-width: 2px;
      border-color: transparent;
      border-radius: 0.75rem;
      overflow: hidden;
    }

    &:hover img {
      border-width: 2px;
      border-color: #f3f4f6;
      border-color: #111827;
    }

    &:has(.is-active) img,
    &.has-focus img {
      border-width: 2px;
      border-color: #1f2937;
      border-color: #e5e7eb;
    }
  }

  .node-aiWriter,
  .node-aiImage,
  .node-tableOfContentsNode {
    &.has-focus [data-node-view-wrapper] > * {
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
      transition: all;
      border-radius: 0.5rem;
    }
  }

  .ProseMirror-gapcursor + .node-imageBlock,
  .ProseMirror-gapcursor + .node-imageUpload,
  .ProseMirror-gapcursor + [data-type="blockquoteFigure"] {
    outline-color: #374151;
    &:hover {
      outline-color: #4b5563;
    }
    outline-color: #d1d5db;
    &:hover {
      outline-color: #9ca3af;
    }
  }

  [data-type="blockquoteFigure"] > div {
    border: 1px solid transparent;
    border-left-width: 4px;
    border-left-color: var(--primary-color);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    background-color: var(--primary-color-07);
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-radius: 0.5rem;

    blockquote {
      margin: 0px;
    }
  }

  [data-type="blockquoteFigure"] > blockquote {
    font-size: 1.125rem;
    color: #000000;
    color: #ffffff;
  }

  [data-type="blockquoteFigure"] figcaption {
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 1rem;
    transition: all;
    overflow: hidden;
    color: #9ca3af;
  }

  .editor-button {
    background-color: var(--button-color);
    color: var(--primary-contrast-color);
    caret-color: var(--primary-contrast-color);
    width: fit-content;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
    
    a {
      color: var(--primary-contrast-color);
      font-weight: 500 !important;
      font-size: 16px !important;
      text-decoration: none !important;
    }

    span {
      &::selection {
        background-color: rgba(
          $color: var(--primary-contrast-color),
          $alpha: 0.5
        );
      }
    }

    &::selection {
      background-color: rgba($color: var(--primary-contrast-color), $alpha: 0.5);
    }
  }
}
