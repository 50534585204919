.news-content-body {
  .is-empty::before {
    position: absolute;
    max-width: 300px;
    content: attr(data-placeholder);
    color: rgba(0, 0, 0, 0.4);
    float: left;
    height: 0;
    pointer-events: none;
    width: 100%;
    white-space: nowrap;
  }

  .dark .is-empty::before {
    color: rgba(255, 255, 255, 0.4);
  }

  &.news-content-body-focused {
    > p.has-focus.is-empty::before {
      content: 'Type / to browse options';
    }

    > [data-type='columns'] > [data-type='column'] > p.is-empty.has-focus::before {
      content: 'Type / to browse options';
    }
  }
/* 
  & > .is-editor-empty::before {
    content: 'Click here to start writing …';
  } */

  blockquote .is-empty:not(.is-editor-empty):first-child:last-child::before {
    content: 'Enter a quote';
  }

  blockquote + figcaption.is-empty:not(.is-editor-empty)::before {
    content: 'Author';
  }

  [data-placeholder][data-suggestion]::before,
  [data-placeholder][data-suggestion] *::before {
    content: none !important;
  }

  [data-type='details'].is-editor-empty::before {
    content: none !important;
  }

  [data-type='details'] summary.is-empty::before {
    content: 'Enter a summary';
  }

  [data-type='detailsContent'] .is-empty::before {
    content: 'Enter details';
  }
}
