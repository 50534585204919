.checklists-details {
  height: calc(100vh - var(--header-tabbar-height));
  width: 100%;
  overflow: auto;
  background-color: var(--background-color);
  z-index: 2;
  position: relative;

  .checklists-details-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    .gleap-loading {
      width: 34px;
      height: 34px;
    }

    .gleap-loading div {
      width: 34px;
      height: 34px;
      border-width: 3px;
    }
  }

  .back-button {
    position: fixed;
    top: 8px;
    left: 8px;
    z-index: 12;

    .icon-button {
      background-color: var(--background-color-99);
      border-radius: var(--border-radius-60);
      width: 44px;
      height: 44px;

      svg path {
        fill: var(--primary-color);
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--primary-color-22) !important;

          svg path {
            fill: var(--primary-color);
          }
        }
      }
    }
  }

  .checklists-title {
    color: var(--primary-text-color);
    padding: 20px;
    padding-bottom: 0px;
    padding-top: 35px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    line-height: 1.4;
  }

  .checklists-description {
    color: var(--primary-text-color);
    padding: 0px 20px;
    padding-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .checklists-sender {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 18px;

    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      border-radius: 100%;
    }

    span {
      color: var(--secondary-text-color);
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .checklist-progress-labels {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    padding-top: 27px;

    .checklist-progress-label {
      font-size: 14px;
      line-height: 20px;
      color: var(--secondary-text-color);
    }
  }

  .checklist-progress-bar {
    width: calc(100% - 40px);
    height: 8px;
    border-radius: 4px;
    margin: 0 20px;
    margin-top: 8px;
    margin-bottom: 20px;
    background-color: var(--background-color-dark);
    overflow: hidden;
    position: relative;
  }

  .checklist-progress-bar-progress {
    width: 0%;
    height: 8px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    background-color: var(--primary-color);
    transition: width 0.3s ease-out;
  }

  .checklist-tasks {
    padding: 0px 20px;
  }

  .checklists-header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background-color);

    &--placeholder {
      height: 65px;
    }
  }

  .checklists-content-skeleton {
    padding: 24px;
    padding-bottom: 0px;
    padding-top: 20px;
    animation: menuFadeIn;
    animation-duration: 0.45s;
    max-width: 550px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.checklists-content--done {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .checklists-title {
    padding-top: 0px !important;
  }
}