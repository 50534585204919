.conversations {
  height: calc(100vh - var(--header-tabbar-height));
  overflow: auto;
  animation: menuFadeIn;
  animation-duration: 0.45s;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  .action-button svg {
    margin-right: 0px;
    margin-left: 10px;
  }

  .conversations-filters {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 8px 10px;

    .conversations-filter {
      padding: 8px 14px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: var(--secondary-text-color);
      cursor: pointer;
      background-color: var(--primary-color-22);
      border-radius: 22px;

      &--active {
        color: white;
        background-color: var(--primary-color);
        font-weight: 500;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.conversations-no-conversations {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: var(--secondary-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--header-tabbar-height));

  .title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: var(--primary-text-color);
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--secondary-text-color);
  }

  .no-messages-yet {
    width: 26px;
    height: auto;
    margin-bottom: 20px;
    fill: var(--primary-text-color);
  }

  .action-button {
    margin-top: 30px;
  }
}

.conversations--filters .conversations-no-conversations {
  height: calc(100vh - var(--header-tabbar-height) - 48px);
}

.conversations-load-more {
  display: flex;
  justify-content: center;
  margin-bottom: 54px;
  margin-top: 20px;

  .gleap-loading {
    width: 34px;
    height: 34px;
  }
  .gleap-loading div {
    width: 34px;
    height: 34px;
    border-width: 3px;
  }
}
