.feedback-flow-container {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - var(--header-height));
  position: relative;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;

  &--chat-style {
    .form-item-label {
      display: none;
    }

    .form-item-description {
      display: none;
    }

    .progress-bar-container {
      display: none;
    }

    .form-item {
      margin-bottom: 12px;
    }

    .form-error {
      box-shadow: none !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-top: 30px !important;
      padding-bottom: 0px !important;
    }

    .form-send-button {
      margin-top: 0px;
    }
  }

  &--form-style {
    .feedback-flow-chat {
      display: none !important;
    }

    .feedback-flow-inner-container {
      max-height: 100% !important;
      box-shadow: none !important;
    }

    .form-container-main {
      background-color: transparent !important;
    }

    .form-intro {
      padding: 0px;
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 22px;
      text-align: left;
      font-weight: 400;
      border-radius: var(--border-radius-50);
      color: var(--primary-text-color);
    }
  }

  .powered-by {
    display: none;
  }

  .feedback-flow-chat {
    min-height: 130px;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .chat-message {
      padding: 15px;
      padding-top: 0px;
      padding-bottom: 6px;
    }

    .avatar {
      margin-bottom: 8px !important;
    }
  }

  .feedback-flow-inner-container {
    max-height: calc(100% - 130px);
    overflow-y: auto;
    display: flex;
    padding: 0px;
    padding-top: 0px;
    flex-shrink: 0;
    box-shadow: rgba(0, 0, 0, 0.02) -1px -4px 10px 3px;
    border-top: 1px solid var(--border-color-33);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    .feedback-flow {
      flex-grow: 1;
      animation: fadeInUp;
      animation-duration: 0.45s;
    }

    .rating-form-item .emojigroup li div {
      width: 43px;
      height: 43px;
    }

    .form-sent,
    .form-loading,
    .form-error {
      margin-left: 0px;
      border-radius: 0px;
      border-top: 0px solid var(--primary-color);
      background-color: var(--background-color);
      box-sizing: border-box;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.06) 0px 2px 8px;
      max-height: initial;
    }

    .form-container-main {
      margin: auto;
      padding: 30px;
      background-color: var(--background-color);
      box-sizing: border-box;
      max-height: initial;
      overflow-y: auto;
      max-width: 100%;

      .form-item-label {
        margin-top: 0px;
      }
    }

    .avatar {
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
      display: flex;
      background-color: var(--secondary-text-color);
      justify-content: center;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 100%;
      }

      svg {
        width: 20px;
        height: 20px;
        object-fit: contain;
        border-radius: 100%;
      }
    }
  }

  .feedback-flow {
    background-color: var(--background-color);
    max-width: 100%;

    &-chat {
      padding: 30px 15px;

      @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
        padding-left: calc(env(safe-area-inset-left) + 15px);
        padding-right: calc(env(safe-area-inset-right) + 15px);
      }

      animation: menuFadeIn;
      animation-duration: 0.45s;

      &--has-input {
        padding-bottom: 15px;
      }
    }

    .form-error {
      margin: 0px;
      margin-bottom: 12px;
    }

    .form-container-main {
      .text-form-item-input {
        padding: 12px 14px;
        border-radius: var(--border-radius-50);
        border: 1px solid var(--border-color-33);
      }

      .textarea-form-item-textarea {
        padding: 12px 14px;
        min-height: 70px;
        resize: none;
        border: none;
        border-radius: var(--border-radius-50);
        border: 1px solid var(--border-color-33);
      }
    }
  }
}

.main-app--survey,
.main-app--survey-full {
  .feedback-flow {
    position: relative !important;
    box-shadow: none;
    margin: 0px;
  }

  .feedback-flow-container {
    height: auto;
  }

  .form-item {
    &:last-of-type {
      margin-bottom: 0px !important;
    }
  }

  .progress-bar-container {
    display: block !important;
  }
}

.main-app--survey-full {
  .feedback-flow {
    padding: 40px;

    @media screen and (max-device-width: 600px) {
      padding: 0px;
    }
  }
}

.onlinestatus {
  font-weight: 400;
  margin-top: -2px;
  margin-bottom: 0px;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &-badge {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    border-radius: 100%;
    background-color: #60bc4e;
    margin-right: 8px;

    &--offline {
      background-color: var(--header-contrast-color);
      opacity: 0.2;
    }
  }

  &-value {
    opacity: 1;
    font-size: 12px;
    line-height: 22px;
    color: var(--header-contrast-color-99);
    max-width: calc(100% - 16px);
  }
}

.suggestions-container {
  margin: auto;
  padding: 30px;
  background-color: var(--background-color);
  box-sizing: border-box;
  max-height: initial;
  overflow-y: auto;
  max-width: 100%;
  max-height: 100%;
  height: 100%;

  .form-duplicates {
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color-55);
    padding: 8px !important;
    width: 100%;
    border-radius: var(--border-radius-60);
    box-shadow: 0px 0px 10px 0px rgba($color: #000000, $alpha: 0.07);
  }

  &--title {
    padding: 0px;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    font-weight: 400;
    border-radius: var(--border-radius-50);
    color: var(--primary-text-color);
  }
}