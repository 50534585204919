.avatar {
  display: none;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 100%;
  }

  svg {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 100%;

    #botbg {
      fill: var(--border-color-33);
    }

    .botclip,
    #botclip {
      fill: var(--primary-text-color);
    }
  }

  &--aibot {
    border-radius: var(--border-radius-50);

    svg {
      border-radius: var(--border-radius-50);
    }
  }
}
