.text-form-item {
  display: flex;
  border-radius: var(--border-radius-50);

  &:focus-within {
    outline: none !important;
    box-shadow: 0 0 0px 3px var(--primary-color-20);
    animation: blink_input 0.01s;
  }

  &-input {
    width: 100%;
    font-size: 14px;
    appearance: none;
    padding: 11px 12px;
    outline: 0;
    line-height: 24px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    box-sizing: border-box;
    color: var(--primary-text-color);
    font-weight: 400;
    border-radius: var(--border-radius-50);

    &:focus {
      outline: none !important;
      border: 1px solid var(--primary-color-AA) !important;
    }

    &::placeholder {
      color: var(--primary-text-color);
      opacity: 0.6;
    }

    &--invalid,
    &--invalid:focus {
      border: 1px solid #ed4337;
    }

    @media screen and (max-device-width: 600px) {
      font-size: 16px;
    }
  }

  &--button {
    .text-form-item-input {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-right: none !important;
    }

    button {
      background-color: var(--primary-color-22);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      border-radius: 0px var(--border-radius-50) var(--border-radius-50) 0px;
      box-sizing: border-box;
      padding: 10px 20px;
      text-align: center;
      width: auto;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;

      svg {
        width: 10px;
        height: auto;
        fill: var(--primary-color);
      }

      &:hover {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        color: var(--primary-contrast-color);

        svg {
          fill: var(--primary-contrast-color);
        }
      }

      &.button--disabled {
        background-color: var(--background-color-darker) !important;
        color: var(--background-color-dark) !important;
        opacity: 1 !important;
        border: 1px solid var(--background-color-dark);
        cursor: not-allowed !important;

        svg {
          fill: var(--background-color-dark);
        }
      }
    }
  }
}

.main-app--survey,
.main-app--survey-full {
  .text-form-item-input {
    @media screen and (max-device-width: 600px) {
      font-size: 16px !important;
    }
  }
}
