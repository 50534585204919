.checklist-task {
  display: flex;
  border-radius: var(--border-radius-60);
  background-color: var(--background-color);
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
  margin-bottom: 12px;
  flex-direction: column;

  &-header {
    display: flex;
    padding: 16px;
    cursor: pointer;

    &--badge {
      color: var(--secondary-text-color);
      background-color: var(--background-color-darker);
      width: 32px;
      height: 32px;
      border-radius: 100%;
      text-align: center;
      line-height: 32px;
      font-weight: 500;
      font-size: 14px;
      flex-shrink: 0;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--secondary-text-color);
      }
    }

    &--title {
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 14px;
      color: var(--primary-text-color);
    }
  }

  &-body {
    padding: 0px;

    &-content {
      padding-left: 60px;
      padding-right: 20px;
      margin-bottom: 20px;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.4;
      color: var(--secondary-text-color);

      > :first-child {
        margin-top: 0px !important;
      }

      p {
        min-height: 14px;
      }

      a,
      p,
      span {
        color: var(--primary-text-color);
        font-size: 14px;
        line-height: 1.4;
        font-weight: 400;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }

      strong * {
        font-weight: 600;
      }

      a {
        color: var(--primary-color);
        text-decoration: underline;
      }

      ul {
        padding-left: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .linktype-button {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 500px;
        object-fit: contain;
        cursor: zoom-in;
      }

      h1,
      h2 {
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--primary-text-color);

        span {
          font-size: 18px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);
        }
      }

      h3 {
        margin-top: 25px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--primary-text-color);

        span {
          font-size: 16px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);
        }
      }

      iframe {
        width: 100%;
        border: 0px !important;
        height: 220px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .iframe-wrapper {
        position: relative;
        padding-bottom: 62.5%;
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 14px;
        line-height: 1.4;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      pre {
        background: #0d0d0d;
        border-radius: 0.5rem;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        font-size: 14px;
        line-height: 18px;
        max-width: 100%;
        overflow: auto;
        margin-top: 20px;
        margin-bottom: 20px;

        code {
          background: none;
          color: inherit;
          font-size: 0.8rem;
          padding: 0;
          font-family: "JetBrainsMono", monospace;
        }
      }
    }

    &-action {
      margin-left: 60px;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;

      .action-button {
        margin-top: 0px !important;
      }
    }

    &-markdone {
      border-top: 1px solid var(--background-color-darker);
      padding-top: 20px;
      padding-bottom: 20px;
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &-icon {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        margin-left: 42px;
        color: var(--primary-color);
      }

      &-label {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: var(--primary-color);
      }
    }
  }

  &--active {
    .checklist-task-header--title {
      font-weight: 600;
    }


    .checklist-task-header--badge {
      background-color: var(--primary-color-11);
      color: var(--primary-color);
    }
  }

  &--done {
    color: var(--secondary-text-color);
    background-color: var(--background-color-darker);
    box-shadow: none;

    .checklist-task-header--badge {
      color: var(--secondary-text-color) !important;
      background-color: var(--background-color-dark) !important;
    }

    .checklist-task-body-markdone {
      border-top: 1px solid var(--background-color-dark);
      cursor: default;

      &-icon {
        color: var(--secondary-text-color);
      }

      &-label {
        color: var(--secondary-text-color);
      }
    }

    .action-button {
      background-color: var(--secondary-text-color) !important;
    }
  }
}