.bot-action-text-multidropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;

  .form-send-button {
    margin-top: 15px !important;
    border-radius: var(--border-radius-50) !important;
  }

  &-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 8px;
    color: var(--primary-text-color);
  }

  &-innercontainer {
    width: 100%;
    position: relative;
  }
}
