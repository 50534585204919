.checklist-card {
  overflow: hidden;
  border-radius: var(--border-radius-60);
  background-color: var(--background-color);
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.06) 0px 2px 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  animation: fadeIn;
  animation-duration: 0.45s;

  &-content {
    padding: 17px 20px;

    &-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 3px;
      color: var(--primary-text-color);
    }

    &-show-details {
      width: 12px;
      height: 12px;
      object-fit: contain;
      flex-shrink: 0;
      margin-left: 12px;

      path {
        fill: var(--primary-color);
      }
    }

    &-body {
      color: var(--primary-text-color);
      font-size: 14px;
      font-weight: 400;
      margin-top: 30px;
    }
  }

  .checklist-progress-labels {
    display: block;
    padding: 0 0px;

    .checklist-progress-label {
      font-size: 14px;
      line-height: 20px;
      max-width: 100%;
      color: var(--secondary-text-color);
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .checklist-progress-bar {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: var(--background-color-dark);
    overflow: hidden;
    position: relative;
  }

  .checklist-progress-bar-progress {
    width: 55%;
    height: 8px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    background-color: var(--primary-color);
    transition: width 0.15s ease-in;
  }

  .checklist-nextstep {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
    max-width: 100%;
    color: var(--secondary-text-color);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    b {
      font-weight: 500;
      color: var(--primary-text-color);
    }
  }

  @media (hover: hover) {
    &:hover {
      .checklist-card-content-title {
        color: var(--primary-color);
      }
    }
  }
}