@keyframes loadAnimation {
  0% {
    width: 0;
  }

  100% {
    width: 95%;
  }
}

@keyframes popInAnimation {
  0% {
    transform: scale(0.4);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.app-modal-view {
  display: flex;
  height: calc(100vh - var(--tabbar-height));
  width: 100vw;

  &-main {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: var(--background-color);
    z-index: 99999;
    animation-name: popInAnimation;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;

    .widget-header {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    }
  }

  iframe {
    height: 100%;
    width: 100%;
    border: 0px;
    margin: 0px;
    padding: 0px;
  }

  &-loader {
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0px;


    &-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: var(--header-contrast-color);
      animation-name: loadAnimation;
      animation-duration: 2s;
      animation-fill-mode: forwards;
    }
  }

  &--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    iframe {
      opacity: 0;
      height: 0px;
      width: 0px;
    }

    .gleap-loading {
      width: 34px;
      height: 34px;
    }

    .gleap-loading div {
      width: 34px;
      height: 34px;
      border-width: 3px;
    }
  }
}