.news-card {
  overflow: hidden;
  border-radius: var(--border-radius-60);
  background-color: var(--background-color);
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.06) 0px 2px 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  animation: fadeIn;
  animation-duration: 0.45s;

  &-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    background-color: var(--background-color-darker);

    @media only all and (min-width: 420px) {
      height: 200px;
    }
  }

  &-content {
    display: flex;
    padding: 17px 20px;
    align-items: center;

    &-content {
      flex-grow: 1;
      width: 100%;
      max-width: calc(100% - 26px) !important;
    }

    &-show-details {
      width: 12px;
      height: 12px;
      object-fit: contain;
      flex-shrink: 0;
      margin-left: 12px;

      path {
        fill: var(--primary-color);
      }
    }

    &-title {
      font-size: 14px;
      line-height: 21px;
      color: var(--primary-text-color);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 600;
      white-space: normal;
      margin-bottom: 4px;
    }

    &-meta {
      align-items: center;
      color: var(--secondary-text-color);
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      overflow-wrap: break-word;
      word-break: break-word;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-body {
      color: var(--primary-text-color);
      font-size: 14px;
      font-weight: 400;
      margin-top: 30px;
    }
  }

  @media (hover: hover) {
    &:hover {
      .news-card-content-title {
        color: var(--primary-color);
      }
    }
  }
}